import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiCoreService } from 'src/app/services/api-core/api-core.service';
import * as firebase from 'firebase';
import { MessageService } from 'primeng/api';
@Component({
  selector: 'app-list-file-customer',
  templateUrl: './list-file-customer.component.html',
  styleUrls: ['./list-file-customer.component.scss']
})
export class ListFileCustomerComponent implements OnInit {
  images: any[];
  @Input() dataForm: any;
  @Output() callbackformFile = new EventEmitter<any>();
  displayCustom = false
  activeIndex = 0
  responsiveOptions:any[] = [
      {
          breakpoint: '1024px',
          numVisible: 5
      },
      {
          breakpoint: '768px',
          numVisible: 3
      },
      {
          breakpoint: '560px',
          numVisible: 1
      }
  ];
  constructor(
    private apiCoreService: ApiCoreService,
    private messageService: MessageService,
  ) { }

  ngOnInit(): void {

  }

  metas = [];
  loading = false
  isupload = true;
  onUploadOutput1(event, metaNote) {
    this.loading = true;
    this.isupload = false
    let files = event.currentFiles;
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const getDAte = new Date();
        const getTime = getDAte.getTime();
        const storageRef = firebase.storage().ref();
        const uploadTask = storageRef.child(`housing/document/${getTime}-${event.currentFiles[i].name}`).put(event.currentFiles[i]);
        uploadTask.on('state_changed', (snapshot) => {
        }, (error) => {
        }, () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            if (downloadURL) {
              const paramsMeta = {
                custId: this.dataForm.custId,
                metas: [{
                  metaId: 0,
                  index: this.metas.length + 1,
                  metaUrl: downloadURL,
                  metaName: event.currentFiles[i].name,
                  metatype: event.currentFiles[i].type,
                  docType: 'cust_doctype1',
                  metaNote: metaNote,
                }]
              };
              this.apiCoreService.setCustProfileMeta(paramsMeta).subscribe((results: any) => {
                if(results.status === 'success') {}
                this.messageService.add({
                  severity: 'success', summary: 'Thông báo', detail: `Thêm tài liệu ${metaNote} thành công! `
                });
                this.isupload = true;
                this.callbackformFile.emit()
                // this.loadSelectedData(this.detailCus)
               }, error => { console.log(error); });
            }
          });
        });
      }
    }
  }

}
