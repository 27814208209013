import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';

/**sunshine component */
import { SunshineComponent } from './sunshine/sunshine.component';
import { HomeComponent } from './home/home.component';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  { path: 'auth-callback', component: AuthCallbackComponent },
  /**Sunshine route */
  {
    path: '', component: SunshineComponent,
    data: { title: '' }, children: [
      { path: 'home', component: HomeComponent },
      {
        path: 'page-help',
        loadChildren: () => import('./pages/page-help/page-help.module').then(m => m.PageHelpModule)
      },
      {
        path: 'page-module-product',
        loadChildren: () => import('./pages/page-module-product/page-module-product.module').then(m => m.PageModuleProductModule)
      },
      {
        path: 'page-voucher',
        loadChildren: () => import('./pages/page-voucher/page-voucher.module').then(m => m.PageVoucherModule)
      },
      {
        path: 'page-user',
        loadChildren: () => import('./pages/page-user/page-user.module').then(m => m.PageUserModule)
      },
      {
        path: 'page-notify',
        loadChildren: () => import('./pages/page-notify/page-notify.module').then(m => m.PageNotifyModule)
      },
      {
        path: 'page-feed-back',
        loadChildren: () => import('./pages/page-feed-back/page-feed-back.module').then(m => m.PageFeedBackModule)
      },
      {
        path: 'quan-ly-khach-hang-vip',
        loadChildren: () => import('./pages/quan-ly-khach-hang-vip/quan-ly-khach-hang-vip.module').then(m => m.QuanLyKhachHangVipModule)
      },
      {
        path: 'page-noti-user',
        loadChildren: () => import('./pages/page-noti-user/page-noti-user.module').then(m => m.PageNotiUserModule)
      },
      {
        path: 'quan-ly-khach-hang-trai-nghiem',
        loadChildren: () => import('./pages/quan-ly-khach-hang-trai-nghiem/quan-ly-khach-hang-trai-nghiem.module').then(m => m.QuanLyKhachHangTraiNghiemModule)
      },

    ], canActivate: [AuthGuardService]

  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
