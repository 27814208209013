import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SplitButtonModule } from 'primeng/splitbutton';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonRendererComponent } from '../components/button-renderer.component';
import { ButtonRendererComponent1 } from '../components/button-renderer.component-1';
import { ButtonRendererMutiComponent } from '../components/button-renderermutibuttons.component';
import { AvatarComponent } from '../components/avatar/avatar.component';
import { ButtonUploadComponent } from '../components/common-form/button-upload.component';
import {FileUploadModule} from 'primeng/fileupload';
import {TooltipModule} from 'primeng/tooltip';
import { AvatarFullComponent } from '../components/avatar/avatarFull.component';
import { ListFileCustomerComponent } from '../components/list-file-customer/list-file-customer.component';
import {GalleriaModule} from 'primeng/galleria';
import { ButtonModule } from 'primeng/button';
@NgModule({
    exports: [
        CommonModule,
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        ButtonRendererComponent,
        ButtonRendererComponent1,
        ButtonRendererMutiComponent,
        AvatarComponent,
        AvatarFullComponent,
        ButtonUploadComponent,
        ListFileCustomerComponent,

    ],
    imports: [
        RouterModule,
        CommonModule,
        SplitButtonModule,
        FormsModule,
        DropdownModule,
        FileUploadModule,
        TooltipModule,
        GalleriaModule,
        ButtonModule
    ],
    declarations: [
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        ButtonRendererComponent,
        ButtonRendererComponent1,
        AvatarComponent,
        AvatarFullComponent,
        ButtonRendererMutiComponent,
        ButtonUploadComponent,
        ListFileCustomerComponent
    ],
    entryComponents: [
        AvatarComponent,
        AvatarFullComponent,
        ListFileCustomerComponent
    ]
})
export class SharedModule { }
