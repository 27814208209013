import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'child-avatar',
  styleUrls: ['./avatar.component.css'],
  template: `
    <div>
    <img  src="{{value}}" alt="" style="width:100%"></div>
    `
})
export class AvatarFullComponent implements OnInit {
  value: string
  constructor() { }

  agInit(params: any) {
    this.value = params.value;
  }
  ngOnInit() {
  }

}
