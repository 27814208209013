<router-outlet>
    <!-- <p-confirmDialog #cd header="Thông báo" icon="pi pi-exclamation-triangle" [transitionOptions]="'0ms'">
        <p-footer>
            <button type="button" pButton icon="pi pi-check" label="Yes" (click)="cd.accept()"></button>
            <button type="button" class="p-button-secondary" pButton icon="pi pi-times" label="No" (click)="cd.reject()"></button>
        </p-footer>
    </p-confirmDialog> -->
    <ngx-spinner></ngx-spinner>
    <p-confirmDialog header="Thông báo" [style]="{width: '20vw'}" [baseZIndex]="999999999" icon="pi pi-exclamation-triangle" [transitionOptions]="'0ms'"></p-confirmDialog>
    <p-confirmPopup key="confirmPopup"></p-confirmPopup>

    <!-- <p-messages></p-messages> -->
    <p-toast position="top-center" baseZIndex="999999999"></p-toast>
</router-outlet>