import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

import { AuthService } from './auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiCoreService } from './api-core/api-core.service';
const apiBaseUrl = environment.apiBase;
const apiksglobalBaseUrl = environment.apiKsGlobalBase;
const apiCoreServer = environment.apiCoreBase;
@Injectable()
export class ApiService {
  constructor(
    private http: HttpClient,
    private authService: AuthService) { }

  options = {
    headers: new HttpHeaders({
      'Authorization': this.authService.getAuthorizationHeaderValue(),
      'Content-Type': 'application/json',
    })
  }
  optionsForUpload = {
    headers: new HttpHeaders({
      'Authorization': this.authService.getAuthorizationHeaderValue(),
    })
  }

  getVoucherInfoPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiksglobalBaseUrl}/api/v1/voucher/GetVoucherInfoPage?` + queryParams, this.options)
  }

  getProjectList(queryParams): Observable<any> {
    return this.http.get<any>(`${apiksglobalBaseUrl}/api/v1/user/GetKsgUserPage?` + queryParams, this.options)
  }

  getKsgUserPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiksglobalBaseUrl}/api/v1/user/GetKsgUserPage?` + queryParams, this.options)
  }

  getModulePage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiksglobalBaseUrl}/api/v1/module/GetModulePage?` + queryParams, this.options)
  }

  getHelperPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiksglobalBaseUrl}/api/v1/help/GetHelperPage?` + queryParams, this.options)
  }

  getVoucherInfo(queryParams): Observable<any> {
    return this.http.get<any>(`${apiksglobalBaseUrl}/api/v1/voucher/GetVoucherInfo?` + queryParams, this.options)
  }

  getModuleInfo(queryParams): Observable<any> {
    return this.http.get<any>(`${apiksglobalBaseUrl}/api/v1/module/GetModuleInfo?` + queryParams, this.options)
  }

  getHelperInfo(queryParams): Observable<any> {
    return this.http.get<any>(`${apiksglobalBaseUrl}/api/v1/help/GetHelperInfo?` + queryParams, this.options)
  }

  getFeedbackInfo(queryParams): Observable<any> {
    return this.http.get<any>(`${apiksglobalBaseUrl}/api/v1/feedback/GetFeedbackInfo?` + queryParams, this.options)
  }

  getFeedbackPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiksglobalBaseUrl}/api/v1/feedback/GetFeedbackPage?` + queryParams, this.options)
  }

  getUserInfo(queryParams): Observable<any> {
    return this.http.get<any>(`${apiksglobalBaseUrl}/api/v1/user/GetUserInfo?` + queryParams, this.options)
  }

  setModuleInfo(queryParams): Observable<any> {
    return this.http.post<any>(`${apiksglobalBaseUrl}/api/v1/module/SetModuleInfo`, queryParams, this.options)
  }

  setVoucherInfo(queryParams): Observable<any> {
    return this.http.post<any>(`${apiksglobalBaseUrl}/api/v1/voucher/SetVoucherInfo`, queryParams, this.options)
  }

  setHelperInfo(queryParams): Observable<any> {
    return this.http.post<any>(`${apiksglobalBaseUrl}/api/v1/help/SetHelperInfo`, queryParams, this.options)
  }

  getAppNotifyPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiksglobalBaseUrl}/api/v1/notify/GetAppNotifyPage?` + queryParams, this.options);
  }

  getNotifyToPushs(queryParams): Observable<any> {
    return this.http.get<any>(`${apiksglobalBaseUrl}/api/v1/notify/GetNotifyToPushs?` + queryParams, this.options);
  }

  getAppNotifyInfo(queryParams): Observable<any> {
    return this.http.get<any>(`${apiksglobalBaseUrl}/api/v1/notify/GetAppNotifyInfo?` + queryParams, this.options);
  }

  getNotifyPushStatus(): Observable<any> {
    return this.http.get<any>(`${apiksglobalBaseUrl}/api/v1/notify/GetNotifyPushStatus`, this.options);
  }

  getNotifyCommentList(queryParams): Observable<any> {
    return this.http.get<any>(`${apiksglobalBaseUrl}/api/v1/notify/GetNotifyCommentList?` + queryParams, this.options);
  }

  getNotifyCommentChilds(queryParams): Observable<any> {
    return this.http.get<any>(`${apiksglobalBaseUrl}/api/v1/notify/GetNotifyCommentChilds?` + queryParams, this.options);
  }

  getObjectList(queryParams): Observable<any> {
    return this.http.get<any>(`${apiksglobalBaseUrl}/api/v1/user/GetObjectList?` + queryParams, this.options);
  }

  getUserByPush(queryParams): Observable<any> {
    return this.http.put<any>(`${apiksglobalBaseUrl}/api/v1/notify/GetUserByPush` , queryParams, this.options);
  }

  getModuleList(): Observable<any> {
    return this.http.get<any>(`${apiksglobalBaseUrl}/api/v1/module/GetModuleList?root=true` , this.options);
  }

  setAppNotifyInfo(params): Observable<any> {
    return this.http.post<any>(`${apiksglobalBaseUrl}/api/v1/notify/SetAppNotifyInfo`, params, this.options);
  }

  setNotifyToPushRun(params): Observable<any> {
    return this.http.put<any>(`${apiksglobalBaseUrl}/api/v1/notify/SetNotifyToPushRun`, params, this.options);
  }

  setNotifyCreatePush(params): Observable<any> {
    return this.http.put<any>(`${apiksglobalBaseUrl}/api/v1/notify/SetNotifyCreatePush`, params, this.options);
  }

  setNotifyCommentAuth(params): Observable<any> {
    return this.http.put<any>(`${apiksglobalBaseUrl}/api/v1/notify/SetNotifyCommentAuth`, params, this.options);
  }

  setNotifyComment(params): Observable<any> {
    return this.http.post<any>(`${apiksglobalBaseUrl}/api/v1/notify/SetNotifyComment`, params, this.options);
  }

  delNotifyPush(queryParams): Observable<any> {
    return this.http.delete<any>(`${apiksglobalBaseUrl}/api/v1/notify/DelNotifyPush?` + queryParams, this.options);
  }

  delAppNotifyInfo(queryParams): Observable<any> {
    return this.http.delete<any>(`${apiksglobalBaseUrl}/api/v1/notify/DelAppNotifyInfo?` + queryParams, this.options);
  }

  delNotifyPushs(queryParams): Observable<any> {
    return this.http.put<any>(`${apiksglobalBaseUrl}/api/v1/notify/DelNotifyPushs`, queryParams, this.options);
  }



  getRepOrderList(queryParams, path): Observable<any> {
    return this.http.get<any>(path + '?' + queryParams, this.options)
  }

  getIdentityCardInfomation(image: File) {
    const options = {
      headers: new HttpHeaders({
        'key': 'WZWfWCN2VPDxbYsV6sRfR0N1fV8x030h'
      })
    };
    const formdata = new FormData();
    formdata.append('image', image, 'TanTano');
    formdata.append('request_id', '14071996');
    return this.http.post('https://api.cloudekyc.com/v3.2/ocr/recognition', formdata, options);
  }

  getBusinessLicense(image: File) {
    const options = {
      headers: new HttpHeaders({
        // 'key': 'WZWfWCN2VPDxbYsV6sRfR0N1fV8x030h'})
        'key': 'Z9Gsi9B0sOK1QVZLayoAMZ2wJPi89Es9'
      })
    };
    const formdata = new FormData();
    formdata.append('image', image, 'TanTano');
    formdata.append('request_id', '14071996');
    return this.http.post('https://api.cloudekyc.com/bl/recognition', formdata, options);
  }

  setDocumentUrl<T>(documentUrl) {
    const doc = {
      projectCd: '',
      documentTitle: '',
      documentUrl
    };
    return this.http.post<T>(apiBaseUrl + '/SetDocumentUrl', doc, this.options).toPromise();
  }

  getDocumentUrl<T>(filter, offset, pagesize) {
    return this.http.get<T>(apiBaseUrl + '/GetDocumentUrl?ProjectCd=&filter=' + filter + '&offSet=' + offset + '&pageSize=' + pagesize, this.options).toPromise();
  }

  GetPriorityCustomerList(queryParams): Observable<any> {
    return this.http.get<any>(`${apiksglobalBaseUrl}/api/v1/priority-customer/GetPriorityCustomerManagePage?` + queryParams, this.options);
  }

  GetPriorityCustomerDetail(queryParams): Observable<any> {
    return this.http.get<any>(`${apiksglobalBaseUrl}/api/v1/priority-customer/GetPriorityCustomerDetail?` + queryParams, this.options);
  }
  
  GetPriorityCustomer(queryParams): Observable<any> {
    return this.http.get<any>(`${apiksglobalBaseUrl}/api/v1/priority-customer/GetPriorityCustomer?` + queryParams, this.options);
  }

  getObjectListInUser(queryParams): Observable<any> {
    return this.http.get<any>(`${apiksglobalBaseUrl}/api/v1/user/GetObjectList?` + queryParams, this.options);
  }

  exportExcelKHUT(queryParams): Observable<any> {
    // @ts-ignore
    return this.http.get<any>(`${apiksglobalBaseUrl}/api/v1/priority-customer/GetReportPriorityCustomerManagePage?` + queryParams, {...this.options, responseType: 'blob'});
  }
  ImportRealEstateInfo(queryParams): Observable<any> {
    return this.http.post<any>(`${apiksglobalBaseUrl}/api/v1/priority-customer/ImportRealEstateInfo`, queryParams, this.optionsForUpload);
  }

  ImportAdvanceCapitalInfo(queryParams): Observable<any> {
    return this.http.post<any>(`${apiksglobalBaseUrl}/api/v1/priority-customer/ImportAdvanceCapitalInfo`, queryParams, this.optionsForUpload);
  }

  ImportPremierInfo(queryParams): Observable<any> {
    return this.http.post<any>(`${apiksglobalBaseUrl}/api/v1/priority-customer/ImportPremierInfo`, queryParams, this.optionsForUpload);
  }

  pushNotifyUser(queryParams): Observable<any> {
    return this.http.post<any>(`${apiksglobalBaseUrl}/api/v1/notify/PushNotifyToUser`, queryParams, this.options);
  }
  
  UpdatePriorityCustomer(queryParams): Observable<any> {
    return this.http.post<any>(`${apiksglobalBaseUrl}/api/v1/priority-customer/UpdatePriorityCustomer`, queryParams, this.options);
  }

  AddNewPriorityCustomer(queryParams): Observable<any> {
    return this.http.post<any>(`${apiksglobalBaseUrl}/api/v1/priority-customer/AddNewPriorityCustomer`, queryParams, this.options);
  }

  GetAgencyOrganizeList(org_level): Observable<any> {
    return this.http.get<any>(`${apiCoreServer}/api/v1/coreagency/GetAgencyOrganizeList?org_level=${org_level}`, this.options);
  }
  
  GetCustomerByCifNo(cifNo): Observable<any> {
    return this.http.get<any>(`${apiksglobalBaseUrl}/api/v1/priority-customer/GetCustomerByCifNo?cifNo=${cifNo}`, this.options);
  }

  // kh trai nghiem
  GetExperiencePriorityCustomerManagePage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiksglobalBaseUrl}/api/v1/priority-customer/GetExperiencePriorityCustomerManagePage?` + queryParams, this.options);
  }

  GetExperiencePriorityCustomer(queryParams): Observable<any> {
    return this.http.get<any>(`${apiksglobalBaseUrl}/api/v1/priority-customer/GetExperiencePriorityCustomer?` + queryParams, this.options);
  }

  CreateExperiencePriorityCustomer(queryParams): Observable<any> {
    return this.http.post<any>(`${apiksglobalBaseUrl}/api/v1/priority-customer/CreateExperiencePriorityCustomer`, queryParams, this.options);
  }

  UpdateExperiencePriorityCustomer(queryParams): Observable<any> {
    return this.http.put<any>(`${apiksglobalBaseUrl}/api/v1/priority-customer/UpdateExperiencePriorityCustomer`, queryParams, this.options);
  }

  DeleteExperiencePriorityCustomer(queryParams): Observable<any> {
    return this.http.delete<any>(`${apiksglobalBaseUrl}/api/v1/priority-customer/DeleteExperiencePriorityCustomer?` + queryParams, this.options);
  }

  UpdateStatusExperiencePriorityCustomer(queryParams): Observable<any> {
    return this.http.put<any>(`${apiksglobalBaseUrl}/api/v1/priority-customer/UpdateStatusExperiencePriorityCustomer`, queryParams, this.options);
  }
  
  // ket thuc
  
  GetCusByCif(queryParams): Observable<any> {
    return this.http.post<any>(`${apiksglobalBaseUrl}/api/v1/notify/GetCusByCif`, queryParams, this.options);
  }
}