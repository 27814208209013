import { Injectable } from '@angular/core';
 import { AuthService } from '../services/auth.service';

import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class HomeService {

  private apiBase = environment.apiBase;
  constructor(private http: HttpClient, private authService: AuthService) { }
  options = { headers: new HttpHeaders({
    'Authorization': this.authService.getAuthorizationHeaderValue(),
    'Content-Type': 'application/json',
  })}

  getEmployeeList<T>(offset, pagesize) {
    return this.http.get<T>(this.apiBase + '/api/v2/employee/GetEmployeeList?ProjectCd=01&offSet=' + offset + '&pageSize=' + pagesize, this.options)
      .toPromise()
  }

}
