import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module'; 
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import { AuthService } from './services/auth.service';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component'; 
import { ExportFileService } from './services/export-file.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from './home/home.component';
import { SunshineComponent } from './sunshine/sunshine.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiService } from './services/api.service';
import { FeedBaseService } from './services/firebase.service';
import { AgGridModule } from '@ag-grid-community/angular';

import { BrowserModule } from '@angular/platform-browser';
import {ButtonModule} from 'primeng/button';
import { FirebaseAuthService } from './services/firebase-auth.service';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import {DialogModule} from 'primeng/dialog';
import { CurrencyDirectiveModule } from './utils/common/currency.module';
import {ToastModule} from 'primeng/toast';
import { ConfirmationService, MessageService } from 'primeng/api';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {MessageModule} from 'primeng/message';
import {MessagesModule} from 'primeng/messages';
import { ApiCoreService } from './services/api-core/api-core.service';
import {ConfirmPopupModule} from 'primeng/confirmpopup';
import { AuthInterceptor } from './services/auth-interceptor';
import { NgxSpinnerModule } from 'ngx-spinner';
@NgModule({
    declarations: [
        AppComponent,
        AuthCallbackComponent,
        HomeComponent,
        SunshineComponent,
    ],
    imports: [
        BrowserAnimationsModule,
        AppRoutingModule,
        SharedModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireStorageModule,
        MessageModule,
        MessagesModule,
        AngularFireAuthModule,
        AngularFirestoreModule,
        AngularFireDatabaseModule,
        ToastModule,
        ConfirmDialogModule,
        NgxSpinnerModule,
        ConfirmPopupModule,
        AgGridModule.withComponents([
            // ButtonRendererComponent,
            // CustomDateComponent,
            // ButtonRendererComponent1
          ]),
          BrowserModule,
          ButtonModule,
          DialogModule,
          CurrencyDirectiveModule
    ],
    entryComponents: [],
    providers: [
        AuthService,
        AuthGuardService,
        FirebaseAuthService,
        ExportFileService,
        ApiService,
        FeedBaseService,
        MessageService,
        ConfirmationService,
        ApiCoreService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        }
       
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
