import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth.service';
const apiBaseUrl = environment.apiBase;
const apiCoreBaseUrl = environment.apiCoreBase;
@Injectable()
export class ApiCoreService {
  constructor(
    private http: HttpClient,
    private authService: AuthService) { }

  options = {
    headers: new HttpHeaders({
      'Authorization': this.authService.getAuthorizationHeaderValue(),
      'Content-Type': 'application/json',
    })
  }

  getCustCoporatePage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/customer/GetCustCoporatePage?` + queryParams, this.options)
  }

  getProfileInfo(queryParams): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coreuser/GetProfileInfo?` + queryParams, this.options)
  }

  getManagerList(queryParams): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coresystem/GetManagerList?` + queryParams, this.options)
  }

  getProfileFields(queryParams): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coreuser/GetProfileFields?` + queryParams, this.options)
  }

  getHolidayPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coresystem/GetHolidayPage?` + queryParams, this.options)
  }

  setProfileCreate(queryParams): Observable<any> {
    return this.http.post<any>(`${apiCoreBaseUrl}/api/v1/coreuser/SetProfileCreate`, queryParams, this.options)
  }

  setWorkApprove(queryParams): Observable<any> {
    return this.http.put<any>(`${apiCoreBaseUrl}/api/v1/customer/SetWorkApprove`, queryParams, this.options)
  }

  setHolidayAdd(queryParams): Observable<any> {
    return this.http.post<any>(`${apiCoreBaseUrl}/api/v1/coresystem/SetHolidayAdd`, queryParams, this.options)
  }

  setProfileInfo(queryParams): Observable<any> {
    return this.http.put<any>(`${apiCoreBaseUrl}/api/v1/coreuser/SetProfileInfo`, queryParams, this.options)
  }

  setHolidayCreate(queryParams): Observable<any> {
    return this.http.post<any>(`${apiCoreBaseUrl}/api/v1/coresystem/SetHolidayCreate`, queryParams, this.options)
  }

  searchCustomer(queryParams): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/customer/GetCustIndividualPage?` + queryParams, this.options);
  }

  setWorkSubmit(params): Observable<any> {
    return this.http.post<any>(`${apiCoreBaseUrl}/api/v1/customer/SetWorkSubmit`, params, this.options);
  }

  getProjectList(projectTypeId): Observable<any> {
    return this.http
      .get<any>(`${apiBaseUrl}/api/v1/shousing/GetProjectList?projectTypeId=${projectTypeId}`, this.options)
  }

  delHoliday(queryParams): Observable<any> {
    return this.http.delete<any>(`${apiCoreBaseUrl}/api/v1/coresystem/DelHoliday?` + queryParams, this.options)
  }

  getCustProfileFields(params): Observable<any> {
    return this.http.post<any>(`${apiCoreBaseUrl}/api/v1/customer/GetCustProfileFields`, params, this.options);
  }

  getWorkflowPage(params): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/customer/GetWorkflowPage?` + params, this.options);
  }

  setCustProfileFields(params): Observable<any> {
    return this.http.put<any>(`${apiCoreBaseUrl}/api/v1/customer/SetCustProfileFields`, params, this.options);
  }

  setCustProfileMeta(params): Observable<any> {
    return this.http.post<any>(`${apiCoreBaseUrl}/api/v1/customer/SetCustProfileMeta`, params, this.options);
  }

  getCustIndividualPage(queryParams,type): Observable<any> {
    if (type == 0) {
      return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/customer/GetCustIndividualPage?` + queryParams, this.options);
    } else {
      return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/customer/GetCustCoporatePage?` + queryParams, this.options);
    }
  }

  removeCustProfile(queryParams): Observable<any> {
    return this.http
      .delete<any>(`${apiCoreBaseUrl}/api/v1/customer/RemoveCustProfile?` + queryParams, this.options)
  }

  delProfileMeta(queryParams): Observable<any> {
    return this.http
      .delete<any>(`${apiCoreBaseUrl}/api/v1/coreuser/DelProfileMeta?` + queryParams, this.options)
  }

  setProfileIdcardVerify(params): Observable<any> {
    return this.http.put<any>(`${apiCoreBaseUrl}/api/v1/coreuser/SetProfileIdcardVerify`,params, this.options);
  }

  getListMenuByUserId(userId, webId): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/corewebmanager/ClientMenuGetListByUserId?` +
        `userId=${userId}&webId=${webId}`, this.options)
  }



}