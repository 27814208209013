import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'child-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.css'],
})
export class AvatarComponent implements OnInit {
  value: string
  constructor() { }

  agInit(params: any) {
    this.value = params.value;
  }
  ngOnInit() {
  }

}
